<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.promotion_program") }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="close">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn
        color="green darken-1"
        text
        @click="beforeConfirm"
        :disabled="isDisabledBtn"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="3">
          <select-multiple
            v-model="item.pos_ids"
            :options="posOptions"
            :label="$t('labels.pos')"
            custom-class="c-input-small"
          ></select-multiple>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="item.name"
            :label="$t('labels.program_name')"
            class="c-input-small"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="item.promotion_type"
            :items="promotionTypeOptions"
            :label="$t('labels.promotion_type')"
            class="c-input-small"
            outlined
            dense
            hide-details
            @change="onPromotionTypeChange"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <div class="d-flex align-center" v-if="item.promotion_type === 1">
            <v-checkbox
              v-model="item.is_discount_sale_product"
              class="mt-0 ml-1 c-input-small"
              :value="1"
              hide-details
              :label="$t('labels.is_discount_sale_product')"
            ></v-checkbox>
          </div>
        </v-col>
        <v-col cols="3">
          <date-picker-model
            v-model="item.start_time"
            :label="$t('labels.start')"
            :min-date="currentDate"
          ></date-picker-model>
        </v-col>
        <v-col cols="3">
          <date-picker-model
            v-model="item.end_time"
            :label="$t('labels.end')"
            :min-date="currentDate"
          ></date-picker-model>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text v-if="item.promotion_type === 1">
      <v-simple-table
        fixed-header
        height="calc(100vh - 400px)"
        class="table-padding-4 mb-3"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.bill_from") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.bill_to") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.discount_by") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.reduce") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.quantity") }}
              </th>
              <th role="columnheader" class="text-center">
                <v-btn color="success" x-small @click="addPromotionBill">
                  {{ $t("labels.add") }}
                </v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(pbItem, pbIndex) in promotionBills"
              :key="`pb_${pbItem.id}_${pbIndex}`"
            >
              <td class="text-center">
                <input-number
                  v-model.number="pbItem.discount_from"
                  outlined
                  dense
                  hide-details
                  type="number"
                  class="c-input-small"
                  @input="sortPromotionBill"
                />
              </td>
              <td class="text-center">
                <input-number
                  v-model.number="pbItem.discount_to"
                  outlined
                  dense
                  hide-details
                  type="number"
                  class="c-input-small"
                  @input="sortPromotionBill"
                />
              </td>
              <td class="text-center">
                <v-autocomplete
                  v-model.number="pbItem.discount_type"
                  :items="discountTypeOptions"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-autocomplete>
              </td>
              <td class="text-center">
                <v-text-field
                  v-if="pbItem.discount_type === 2"
                  v-model.number="pbItem.discount_value"
                  type="number"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                <input-number
                  v-else
                  v-model.number="pbItem.discount_value"
                  outlined
                  dense
                  hide-details
                  type="number"
                  class="c-input-small"
                />
              </td>
              <td class="text-center">
                <input-number
                  v-model.number="pbItem.limit"
                  outlined
                  dense
                  hide-details
                  type="number"
                  class="c-input-small"
                />
              </td>
              <td class="text-center">
                <v-btn
                  color="error"
                  x-small
                  @click="removePromotionBill(pbIndex)"
                  >Xóa</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-text v-else-if="[2, 3].includes(item.promotion_type)">
      <v-simple-table
        fixed-header
        height="calc(100vh - 400px)"
        class="table-padding-4 mb-3"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.promotion_code") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.barcode") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 105px">
                {{ $t("labels.quantity") }}
                <span v-if="item.promotion_type === 2"
                  >({{ $t("labels.from") }})</span
                >
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.price_1sp") }}
              </th>
              <th
                v-if="item.promotion_type === 2"
                role="columnheader"
                class="text-center"
                style="width: 105px"
              >
                {{ $t("labels.base_price_1sp") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 105px">
                {{ $t("labels.total_base_price") }}
              </th>
              <th
                v-if="item.promotion_type === 3"
                role="columnheader"
                class="text-center"
                style="width: 105px"
              >
                {{ $t("labels.total_sale_price") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 105px">
                {{ $t("labels.discount_by_percent_1") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 105px">
                {{ $t("labels.discount_by_price_1") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 122px">
                <v-btn
                  color="success"
                  x-small
                  @click="addPromotionProduct"
                  class="mr-1"
                >
                  {{ $t("labels.add") }}
                </v-btn>
                <v-btn
                  color="primary"
                  outlined
                  x-small
                  @click="$refs.inputUploadFile.click()"
                  class=""
                >
                  Excel
                </v-btn>
                <input
                  type="file"
                  ref="inputUploadFile"
                  accept=".xlsx"
                  @change="onInputFileChange"
                  class="d-none"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(ppItem, ppIndex) in promotionProducts"
              :key="`pp_${ppItem.id}_${ppIndex}`"
            >
              <td>
                <v-text-field
                  v-model="ppItem.code"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </td>
              <td>
                <div
                  v-for="(pItem, pIndex) in ppItem.items"
                  :key="`pib_${ppItem.id}_${ppIndex}_${pIndex}`"
                  :class="{ 'mb-1': ppItem.items.length > 1 }"
                >
                  <v-text-field
                    v-model="pItem.barcode"
                    class="c-input-small"
                    outlined
                    dense
                    hide-details
                    @keyup.enter="getOneGoods(ppItem)"
                    @blur="getOneGoods(ppItem)"
                  ></v-text-field>
                </div>
              </td>
              <td class="text-center">
                <div
                  v-for="(pItem, pIndex) in ppItem.items"
                  :key="`piq_${ppItem.id}_${ppIndex}_${pIndex}`"
                  :class="{ 'mb-1': ppItem.items.length > 1 }"
                >
                  <v-text-field
                    v-model.number="pItem.quantity"
                    class="c-input-small"
                    type="number"
                    outlined
                    dense
                    hide-details
                    @input="changeProductQuantity"
                  ></v-text-field>
                </div>
              </td>
              <td class="text-center">
                <div
                  v-for="(pItem, pIndex) in ppItem.items"
                  :key="`piq_${ppItem.id}_${ppIndex}_${pIndex}`"
                  :class="{ 'mb-1': ppItem.items.length > 1 }"
                >
                  <input-number
                    v-model.number="pItem.price_per_item"
                    outlined
                    dense
                    hide-details
                    type="number"
                    class="c-input-small"
                    @input="changeProductQuantity"
                  />
                </div>
              </td>
              <td class="text-center" v-if="item.promotion_type === 2">
                {{ formatNumber(ppItem.one_origin_price) }} đ
              </td>
              <td class="text-center">
                {{ formatNumber(ppItem.origin_price) }} đ
              </td>
              <td
                v-if="item.promotion_type === 3"
                class="text-center font-weight-medium error--text"
              >
                {{ formatNumber(ppItem.sale_price) }} đ
              </td>
              <td class="text-center">
                {{ formatNumber(ppItem.discount_percent * 100, 2) }} %
              </td>
              <td class="text-center">
                {{ formatNumber(ppItem.discount_value) }} đ
              </td>
              <td class="text-center">
                <v-btn
                  v-if="item.promotion_type === 3"
                  color="primary"
                  x-small
                  class="mr-1"
                  @click="addPromotionProductItem(ppItem, ppIndex)"
                >
                  {{ $t("labels.add_item") }}
                </v-btn>
                <v-btn
                  color="error"
                  x-small
                  @click="removePromotionProduct(ppIndex)"
                >
                  {{ $t("labels.delete") }}
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-text v-else-if="[4].includes(item.promotion_type)">
      <v-simple-table
        fixed-header
        height="calc(100vh - 400px)"
        class="table-padding-4 mb-3"
      >
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center" style="width: 15%">
              {{ $t("labels.uid") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.barcode") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.base_price") }}
            </th>
            <th role="columnheader" class="text-center" style="width: 20%">
              {{ $t("labels.sale_price_1") }}
            </th>
            <!--<th role="columnheader" class="text-center">{{ $t('labels.discount_by') }}</th>
                        <th role="columnheader" class="text-center">{{ $t('labels.reduce') }}</th>-->
            <th role="columnheader" class="text-center" style="width: 10%">
              <v-btn
                color="success"
                x-small
                @click="addPromotionUid"
                class="mr-1"
              >
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody class="text-center">
          <tr
            v-for="(puItem, puIndex) in promotionUids"
            :key="`pb_${puItem.id}_${puIndex}`"
          >
            <td class="text-center">
              <v-text-field
                v-model.trim="puItem.sku_uid"
                class="c-input-small"
                outlined
                dense
                hide-details
                @keyup.enter="getUid(puItem, puIndex)"
                @blur="getUid(puItem, puIndex)"
              ></v-text-field>
            </td>
            <td>{{ puItem.customer_goods_barcode }}</td>
            <td>
              <span v-if="puItem.origin_price"
                >{{ formatNumber(puItem.origin_price) }} đ</span
              >
            </td>
            <!--<td class="text-center" style="width: 20%;">
                            <v-autocomplete v-model.number="puItem.discount_type" :items="discountTypeOptions"
                                label="Giảm theo" class="c-input-small" outlined dense hide-details
                                single-line></v-autocomplete>
                        </td>-->
            <td class="text-center">
              <!--<v-text-field v-if="puItem.discount_type === 2" v-model.number="puItem.discount_value"
                                label="Giảm (%)" type="number" class="c-input-small" outlined dense
                                hide-details></v-text-field>-->
              <input-number
                v-model.number="puItem.discount_value"
                outlined
                dense
                hide-details
                type="number"
                class="c-input-small"
              />
            </td>
            <td class="text-center">
              <v-btn color="error" x-small @click="removePromotionUid(puIndex)">
                {{ $t("labels.delete") }}
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment/moment";
import { httpClient } from "@/libs/http";
import _ from "lodash";
import { debounce } from "lodash/function";
import { read, utils } from "xlsx";
import { POS_PROMOTION_TYPES } from "@/libs/const";

export default {
  name: "PromotionCreate",
  data: () => ({
    posOptions: [],
    startDate: null,
    startTime: "00:00",
    endDate: null,
    endTime: "23:59",
    item: {
      pos_ids: [],
      name: null,
      promotion_type: null,
      start_time: null,
      end_time: null,
      is_discount_sale_product: 0,
    },
    promotionBills: [],
    promotionProducts: [],
    promotionUids: [],
    isLoading: false,
    goodsPriceMapping: {},
    goodsIdMapping: {},
    goods: [],
  }),
  computed: {
    currentDate() {
      return moment().format("YYYY-MM-DD");
    },
    isDisabledBtn() {
      if (!this.item.pos_ids || this.item.pos_ids.length === 0) {
        return true;
      }
      if (!this.item.name || !this.item.promotion_type) {
        return true;
      }
      if (
        this.promotionBills.length === 0 &&
        this.promotionProducts.length === 0 &&
        this.promotionUids.length === 0
      ) {
        return true;
      }
      if (!this.item.start_time || !this.item.end_time) {
        return true;
      }
      /* const start = moment(this.startTimeConverted).startOf('minute').unix()
            const end = moment(this.endTimeConverted).endOf('minute').unix()
            if (start > end) {
                return true
            } */
      return false;
    },
    startTimeConverted() {
      return !this.startDate || !this.startTime
        ? null
        : `${this.startDate} ${this.startTime}`;
    },
    endTimeConverted() {
      return !this.endDate || !this.endTime
        ? null
        : `${this.endDate} ${this.endTime}`;
    },
    promotionTypeOptions() {
      return POS_PROMOTION_TYPES;
    },
    discountTypeOptions() {
      return [
        {
          text: "Theo giá trị (đ)",
          color: "success--text",
          value: 1,
        },
        {
          text: "Theo phần trăm (%)",
          color: "primary--text",
          value: 2,
        },
        {
          text: "Giảm còn (đ)",
          color: "error--text",
          value: 3,
        },
      ];
    },
  },
  mounted() {
    const { poss } = window.me;
    this.posOptions = [...poss].map((w) => ({
      value: w.id,
      text: w.code || "",
    }));
  },
  methods: {
    async onInputFileChange(e) {
      const mappingFields = {
        "Mã KM": "code",
        Barcode: "barcode",
        "Số lượng": "quantity",
        "Giá 01 sp": "price_per_item",
      };
      const files = e.target.files,
        f = files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        let sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const values = utils.sheet_to_json(worksheet);
        const items = [...values].map((v) => {
          const item = {};
          Object.keys(v).forEach((i) => {
            item[mappingFields[i]] = `${v[i]}`.trim();
          });
          return item;
        });
        this.remapData(items);
      };
      await reader.readAsArrayBuffer(f);
    },
    remapData(items) {
      const data = [];
      const barcodes = [];
      items.forEach((item) => {
        if (!barcodes.includes(item.barcode)) {
          barcodes.push(item.barcode);
        }
        const checkItem = data.find((d) => d.code === item.code);
        if (!checkItem || this.item.promotion_type === 2) {
          data.push({
            ...item,
            items: [
              {
                barcode: item.barcode,
                quantity: item.quantity,
                price_per_item: item.price_per_item,
              },
            ],
          });
        } else {
          const checkItemIndex = data.findIndex((d) => d.code === item.code);
          const checkItemGoods = checkItem.items.find(
            (g) => g.barcode === item.barcode
          );
          if (!checkItemGoods || checkItemGoods.length === 0) {
            checkItem.items.push({
              barcode: item.barcode,
              quantity: item.quantity,
              price_per_item: item.price_per_item,
            });
          } else {
            const checkItemGoodsIndex = checkItem.items.findIndex(
              (g) => g.barcode === item.barcode
            );
            let quantity = +checkItem.items[checkItemGoodsIndex].quantity;
            let barcode = checkItem.items[checkItemGoodsIndex].barcode;
            let price_per_item =
              checkItem.items[checkItemGoodsIndex].price_per_item;
            quantity = quantity + +item.quantity;
            checkItem.items[checkItemGoodsIndex] = {
              barcode,
              quantity,
              price_per_item,
            };
          }
          data[checkItemIndex] = { ...checkItem };
        }
      });

      this.getGoods({ barcodes, promotions: data });
    },
    getOneGoods(promotion) {
      const barcodes = promotion.items.map((p) => p.barcode);
      this.getGoods({ barcodes, promotions: this.promotionProducts });
    },
    calPromotionPrice(promotion) {
      let originPrice = 0;
      let oneOriginPrice = 0;
      let salePrice = 0;
      const pItems = promotion.items;
      for (let i = 0; i < pItems.length; i++) {
        const pItem = pItems[i];
        oneOriginPrice = this.goodsPriceMapping[pItem.barcode];
        originPrice += this.goodsPriceMapping[pItem.barcode] * pItem.quantity;
        salePrice += pItem.price_per_item * pItem.quantity;
      }
      const discountValue = originPrice - salePrice;
      const discountPercent = discountValue / originPrice;
      const oneDiscountValue = oneOriginPrice - salePrice;
      const oneDiscountPercent = oneDiscountValue / oneOriginPrice;
      return {
        salePrice,
        originPrice,
        discountValue,
        discountPercent,
        oneOriginPrice,
        oneDiscountValue,
        oneDiscountPercent,
      };
    },
    changeProductQuantity: debounce(function () {
      this.updatePromotionPrices(this.promotionProducts);
    }, 1000),
    updatePromotionPrices(promotions) {
      this.promotionProducts = [...promotions].map((promotion) => {
        const {
          salePrice,
          originPrice,
          discountValue,
          discountPercent,
          oneOriginPrice,
          oneDiscountValue,
          oneDiscountPercent,
        } = this.calPromotionPrice(promotion);
        promotion.items = [...promotion.items].map((item) => ({
          ...item,
          id_goods: this.goodsIdMapping[item.barcode],
        }));
        promotion.sale_price = salePrice;
        promotion.origin_price = originPrice;
        promotion.discount_value = discountValue;
        promotion.discount_percent = discountPercent;
        promotion.one_origin_price = oneOriginPrice;
        promotion.one_discount_value = oneDiscountValue;
        promotion.one_discount_percent = oneDiscountPercent;
        return promotion;
      });
    },
    async getGoods({ barcodes, promotions }) {
      const { data } = await httpClient.post("/get-goods-by-barcodes", {
        barcodes,
      });
      if (data.length === 0) {
        this.$vToastify.error(this.$t("messages.goods_not_found"));
        return false;
      }

      const notPrices = [];
      const resBarcodes = [];
      for (let i = 0; i < data.length; i++) {
        const goods = data[i];
        resBarcodes.push(goods.customer_goods_barcode);
        this.goodsIdMapping[goods.customer_goods_barcode] = goods.id;
        if (goods.price === null) {
          notPrices.push(goods.customer_goods_barcode);
        } else {
          this.goodsPriceMapping[goods.customer_goods_barcode] = goods.price;
        }
      }

      if (notPrices && notPrices.length > 0) {
        this.$vToastify.error(
          this.$t("messages.no_price_barcodes", {
            barcodes: notPrices.join("; "),
          })
        );
        return false;
      }

      const notFounds = [...barcodes].filter((b) => !resBarcodes.includes(b));
      if (notFounds && notFounds.length > 0) {
        this.$vToastify.error(
          this.$t("messages.not_found_barcodes", {
            barcodes: notFounds.join("; "),
          })
        );
        return false;
      }

      this.updatePromotionPrices(promotions);
    },
    close() {
      this.$emit("close");
    },
    onPromotionTypeChange() {
      this.promotionProducts = [];
      this.promotionBills = [];
      if (this.item.promotion_type === 1) {
        this.addPromotionBill();
      }
    },
    addPromotionBill() {
      const item = {
        id: this.generateRandomString(5),
        discount_from: "",
        discount_to: "",
        limit: "",
        discount_type: null,
        discount_value: "",
      };
      if (this.promotionBills.length > 0) {
        const lastItem = this.promotionBills[this.promotionBills.length - 1];
        item.discount_type = lastItem.discount_type;
      }
      this.promotionBills.push(item);
    },
    removePromotionBill(pbIndex) {
      this.promotionBills = [...this.promotionBills].filter(
        (item, index) => index !== pbIndex
      );
    },
    addPromotionProductItem(ppItem, ppIndex) {
      ppItem.items.push({ barcode: null, quantity: 1, price_per_item: "" });
      const promotions = [...this.promotionProducts];
      promotions[ppIndex] = ppItem;
      this.promotionProducts = [...promotions];
    },
    addPromotionProduct() {
      const item = {
        barcode: null,
        quantity: 1,
        price_per_item: "",
      };
      const items = [item];
      if (this.item.promotion_type === 3) {
        items.push(item);
      }
      this.promotionProducts.unshift({
        id: this.generateRandomString(5),
        code: null,
        sale_price: "",
        origin_price: null,
        discount_percent: null,
        discount_value: null,
        items: items,
      });
    },
    removePromotionProduct(ppIndex) {
      this.promotionProducts = [...this.promotionProducts].filter(
        (item, index) => index !== ppIndex
      );
    },
    sortPromotionBill: debounce(function () {
      const sorts = _.sortBy(this.promotionBills, "discount_from");
      this.promotionBills = [...sorts];
    }, 1000),
    addPromotionUid() {
      const item = {
        id: this.generateRandomString(5),
        sku_uid: "",
        customer_goods_barcode: null,
        origin_price: null,
        discount_value: "",
      };
      /* if (this.promotionUids.length > 0) {
                const lastItem = this.promotionUids[this.promotionUids.length - 1]
                item.discount_type = lastItem.discount_type
            } */
      this.promotionUids.push(item);
    },
    removePromotionUid(pbIndex) {
      this.promotionUids = [...this.promotionUids].filter(
        (item, index) => index !== pbIndex
      );
    },
    async getUid(promotion, pbIndex) {
      if (!promotion.sku_uid || !promotion.sku_uid.trim()) {
        return false;
      }

      const checkDuplicate = [...this.promotionUids].find(
        (pu, pi) => pu.sku_uid == promotion.sku_uid.trim() && pi != pbIndex
      );
      if (checkDuplicate) {
        this.$vToastify.error(
          this.$t("messages.duplicate_uid", { uid: checkDuplicate.sku_uid })
        );
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/pos-get-item", {
          code: promotion.sku_uid,
        });
        if (!data || !data.available) {
          this.$vToastify.error(this.$t("messages.uid_unavailable"));
          this.isLoading = false;
          return false;
        }

        const checkDuplicateId = [...this.promotionUids].find(
          (pu, pi) => pu.id == data.id && pi != pbIndex
        );
        if (checkDuplicateId) {
          this.$vToastify.error(
            this.$t("messages.duplicate_uid", { uid: checkDuplicateId.sku_uid })
          );
          this.isLoading = false;
          return false;
        }

        promotion = {
          ...promotion,
          id: data.id,
          customer_goods_barcode: data.customer_goods_barcode,
          origin_price: data.price,
        };
        const promotionUids = [...this.promotionUids];
        promotionUids[pbIndex] = { ...promotion };
        this.promotionUids = [...promotionUids];
        this.isLoading = false;
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    beforeConfirm() {
      let canSubmit = true;
      let checkBill = [];
      let checkProduct = [];
      let checkCombo = [];
      switch (this.item.promotion_type) {
        case 1:
          checkBill = this.promotionBills.filter(
            (b) =>
              !b.discount_type ||
              !b.discount_value /* || (!b.discount_from && b.discount_from !== 0) */
          );
          if (checkBill.length > 0) {
            this.$vToastify.error(this.$t("messages.promotion_bill_not_null"));
            canSubmit = false;
          }
          break;
        case 2:
          if (checkProduct.length > 0) {
            this.$vToastify.error(
              this.$t("messages.promotion_product_duplicate_code")
            );
            canSubmit = false;
          }
          break;
        case 3:
          if (checkCombo.length > 0) {
            this.$vToastify.error(
              this.$t("messages.promotion_combo_duplicate_barcode")
            );
            canSubmit = false;
          }
          break;
        default:
      }

      if (!canSubmit) {
        return false;
      }

      this.confirm();
    },

    async confirm() {
      if (this.isDisabledBtn) {
        return false;
      }
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      const dataCreate = { ...this.item };

      if (dataCreate.promotion_type === 1) {
        dataCreate.bills = this.promotionBills;
      } else if ([2, 3].includes(dataCreate.promotion_type)) {
        dataCreate.products = this.promotionProducts;
      } else {
        dataCreate.uids = this.promotionUids;
      }

      try {
        await httpClient.post("/pos-promotion-save", dataCreate);
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.create_success"));
        this.$emit("refreshData");
        this.close();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    onStartDateChange(val) {
      this.startDate = val.value;
    },
    onEndDateChange(val) {
      this.endDate = val.value;
    },
  },
};
</script>

<style scoped></style>
